import { useSelector } from 'react-redux';
import { ConnectionStrings } from '../constants/ConnectionStrings';
import { useState } from 'react';
import SinglePhotoView from './SinglePhotoView';
import { Box, ImageList, ImageListItem } from "@mui/material";

import "./CategoryViewPage.css"
import { ContactlessOutlined } from '@mui/icons-material';

function CategoryViewPage(props){

    const builds = useSelector((state) => state.buildReducer.builds);
    const [showSinglePhoto, setShowSinglePhoto] = useState(false);
    const [singlePhotoToShow, setSinglePhotoToShow] = useState(null);
    const [singlePhotoIndex, setSinglePhotoIndex] = useState(0);
    var photos = [];

    if(props.id === null){  //Should be null if config is to not use category views
        builds.forEach((build) => photos = photos.concat(build.photoFileNames));
    }else{
        builds.forEach((build) => {
            if(build.galleryCategoryId === props.id){
                photos = photos.concat(build.photoFileNames)
            }
        });
    }

    const handleSinglePhotoClick = (e, index) => {
        setSinglePhotoToShow(e.target.src)
        setShowSinglePhoto(true);
        setSinglePhotoIndex(index);
        console.log("Photo Clicked Index set to: " + index)
    };

    const closeSinglePhoto = () => {
        setShowSinglePhoto(false);
    }

    const advanceSinglePhotoForward = () => {

        console.log("FORWARD Starting Index: " + singlePhotoIndex)

        if(singlePhotoIndex + 1 > photos.length - 1){
            setSinglePhotoIndex(0);
        }else{
            setSinglePhotoIndex(singlePhotoIndex + 1);
        }
        console.log("FORWARD New INdex: " + (singlePhotoIndex + 1))
        let selectedPhoto = photos.filter((item, index) => index === singlePhotoIndex + 1)[0];
        setSinglePhotoToShow(`${ConnectionStrings.Services}\\${adjustSourceURI(selectedPhoto)}`)
    }

    const advanceSinglePhotoBackward = () => {

        console.log("BACK Starting Index: " + singlePhotoIndex)

        if(singlePhotoIndex - 1 < 0){
            setSinglePhotoIndex(photos.length - 1);
            let selectedPhoto = photos.filter((item, index) => index === photos.length - 1)[0];
            setSinglePhotoToShow(`${ConnectionStrings.Services}\\${adjustSourceURI(selectedPhoto)}`)
        }else{
            setSinglePhotoIndex(singlePhotoIndex - 1);
            let selectedPhoto = photos.filter((item, index) => index === singlePhotoIndex - 1)[0];
            setSinglePhotoToShow(`${ConnectionStrings.Services}\\${adjustSourceURI(selectedPhoto)}`)
        }
        console.log("BACK New INdex: " + (singlePhotoIndex - 1))
        
    }


    const adjustSourceURI = (uri) => {

        if(uri.toLowerCase().endsWith(".jpg") || uri.toLowerCase().endsWith(".jpeg") || uri.toLowerCase().endsWith(".png")){
            return uri;
        }else{
            //Try to make it a jpg
            return uri+".jpg";
        }
    }

    const photoListView = <Box className="noscroll" sx={{ width: "70vw", height: "100vh", overflowY: 'scroll' }}>
                            <ImageList variant="masonry" cols={3} gap={8}>
                                {photos.map((photo, index) => (
                                <ImageListItem key={index} sx={{cursor: "pointer"}}>
                                    <img
                                    src={`${ConnectionStrings.Services}\\${adjustSourceURI(photo)}`}
                                    onClick={(e) => handleSinglePhotoClick(e, index) }
                                    alt={"N/A"}
                                    loading="eager"
                                    />
                                </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>

    return(!showSinglePhoto ? photoListView : <SinglePhotoView photo={singlePhotoToShow} close={closeSinglePhoto} forward={advanceSinglePhotoForward} back={advanceSinglePhotoBackward}/>)
}
    
export default CategoryViewPage;