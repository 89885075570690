import {ThemeProvider, createTheme} from "@mui/material/styles";
import NavigationBar from "./NavigationBar/NavigationBar";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getTestimonials } from "../redux/actions/TestimonialActions";
import { Box, Typography, Tab, Tabs } from "@mui/material";

import "./TestimonialsMainPage.css";

const theme = createTheme(
    {
        typography: {
            fontFamily: ['Trebuchet MS', 'sans-serif'].join(','),
            fontSize: 11,
            fontWeight: 800
        },
    }
)

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, width: "500px" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }



function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

function TestimonialsMainPage(){

    const testimonials = useSelector((state) => state.testimonialReducer.testimonials);
    const [value, setValue] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        doFetchTestimonials();              
    }, [])
    
    const doFetchTestimonials = async () => {
      dispatch(getTestimonials());
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };


    return(
        <ThemeProvider theme={theme}>  

             <NavigationBar bgcolor="L"/>
             <div className="page-container">
             <div className="TestimonialWidgetWrapper">
             <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', justifyContent: "center", marginTop: "200px" }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        {testimonials.map((testimonial, index) => (
                                     <Tab label={"The " + testimonial.lastName + "'s"} {...a11yProps(index)} />
                                     ))}
                    </Tabs>

                    {testimonials.map((testimonial, index) => (
                        <TabPanel value={value} index={index}>
                            <div className="">
                            <Testimonial data={testimonial} />
                            </div>
                        </TabPanel>
                    ))}
                    </Box></div></div>




             {/*<Grid xs={12} alignItems={"center"} justifyContent={"center"} >

                    {testimonials.map((testimonial) => (
                                     <Grid item xl={true} align={"center"} paddingTop={"50px"}>
                                        <Testimonial data={testimonial}></Testimonial>
                                     </Grid>))}

                    </Grid>*/}
             <div className="footer">
             <Footer /></div>
        </ThemeProvider>
    );
    
    }
    
export default TestimonialsMainPage;